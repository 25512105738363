import { type UserLocation, useGetPlaceSuggestionsLazyQuery } from '@kijiji/generated/graphql-types'
import LocationOutlineIcon from '@kijiji/icons/src/icons/LocationOutline'
import { useDecision } from '@optimizely/react-sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { LocalStorageRegistry } from '@/constants/localStorageRegistry'
import {
  type LocationErrorType,
  type LocationOption,
  LOCATION_ERROR_TYPE,
  LOCATION_OPTION_TYPE,
} from '@/constants/location'
import { dedupeAndSliceRecentLocations } from '@/domain/location/dedupeAndSliceRecentLocations'
import { getRecentLocationsFromLocalStorage } from '@/domain/location/getRecentLocationsFromLocalStorage'
import { normalizeRecentLocationSuggestions } from '@/domain/location/normalizeRecentLocationSuggestions'
import GET_PLACE_SUGGESTIONS from '@/graphql/google-maps/GetPlaceSuggestions.gql'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { sendToLogger } from '@/utils/sendToLogger'

import { useGooglePlaceToken } from './useGooglePlaceToken'
import { useLiveLocation } from './useLiveLocation'

export type RecentSearchLocation = {
  address?: string
  internalId: number
  internalName?: string
  isProvinceOrCountry: boolean
  isSearchAllArea: boolean
  latitude?: number
  longitude?: number
  mapRadius?: number
}

export const useLocationSearchSuggestions = () => {
  const { liveLocationOption, nearLiveLocationOption } = useLiveLocation()
  const [recentSearchLocations, setRecentSearchLocations] = useState<RecentSearchLocation[] | []>(
    []
  )
  const [getLocationSuggestions] = useGetPlaceSuggestionsLazyQuery()
  const { getGooglePlaceToken } = useGooglePlaceToken()
  const [decision] = useDecision(FEATURE_FLAG.SEARCH_AROUND_ME_EXP)
  const isSearchAroundMeExperimentEnabled = decision?.enabled === true
  const showSearchAroundMeFeature =
    isSearchAroundMeExperimentEnabled && decision?.variationKey === 'b'

  const initialSuggestions = useMemo(
    () => [
      liveLocationOption,
      ...(showSearchAroundMeFeature ? [nearLiveLocationOption] : []),
      ...normalizeRecentLocationSuggestions(recentSearchLocations),
    ],
    [liveLocationOption, nearLiveLocationOption, recentSearchLocations, showSearchAroundMeFeature]
  )

  // Initialize the search suggestions with the live location ("Use current location") and recent search locations if they exist
  const [suggestions, setSuggestions] = useState<LocationOption[]>(initialSuggestions)

  const resetSuggestions = useCallback(() => {
    setSuggestions(initialSuggestions)
  }, [initialSuggestions])

  /** Get recent search locations from local storage once the window object is defined */
  useEffect(() => {
    const storedRecentSearchLocations = getRecentLocationsFromLocalStorage()
    const minSuggestionsLength = showSearchAroundMeFeature ? 2 : 1
    setRecentSearchLocations(storedRecentSearchLocations)
    setSuggestions((prevSuggestions) => {
      /** Update suggestions if they have not yet been updated. Suggestions will always have at least one element - "Use current location" */
      if (prevSuggestions.length > minSuggestionsLength) return prevSuggestions
      return [
        ...prevSuggestions,
        ...normalizeRecentLocationSuggestions(storedRecentSearchLocations),
      ]
    })
  }, [showSearchAroundMeFeature])

  /** When a user sets a new location, store it in the RecentSearchLocations localStorage object */
  const setRecentSearchLocation = (newLocation: RecentSearchLocation) => {
    const updatedRecentSearchLocations = dedupeAndSliceRecentLocations(
      recentSearchLocations,
      newLocation
    )

    window.localStorage.setItem(
      LocalStorageRegistry.RECENT_LOCATIONS,
      JSON.stringify(updatedRecentSearchLocations)
    )

    setRecentSearchLocations(updatedRecentSearchLocations)
  }

  /** Triggered on search query update */
  const fetchGoogleLocationSuggestions = useCallback(
    async (
      newSearchQuery: string,
      localLocation: UserLocation
    ): Promise<LocationErrorType | undefined> => {
      try {
        const { area } = localLocation
        const googlePlaceToken = await getGooglePlaceToken()
        const { data, error } = await getLocationSuggestions({
          query: GET_PLACE_SUGGESTIONS,
          variables: {
            input: newSearchQuery,
            location: area ? { latitude: area.latitude, longitude: area.longitude } : undefined,
          },
          context: {
            headers: { [CookieRegistry.GOOGLE_PLACE_TOKEN]: googlePlaceToken },
          },
        })

        const placeSuggestions = data?.placeSuggestions

        if (error || !data || !placeSuggestions || placeSuggestions.length === 0) {
          setSuggestions([])
          return LOCATION_ERROR_TYPE.GOOGLE_LOCATION_ERROR
        }

        const newPlaceSuggestions = placeSuggestions.map((suggestion) => {
          return {
            ...suggestion,
            type: LOCATION_OPTION_TYPE.GOOGLE_LOCATION,
            address: suggestion?.address ?? '',
            suggestionId: suggestion?.placeId ?? '',
            icon: <LocationOutlineIcon aria-hidden />,
          }
        })

        setSuggestions(newPlaceSuggestions)
      } catch {
        sendToLogger('Unknown error occurred', {
          tags: { fn: 'fetchGoogleLocationSuggestions', component: 'useLocationSearchSuggestions' },
          fingerprint: ['useLocationSearchSuggestions'],
        })
        return LOCATION_ERROR_TYPE.GOOGLE_LOCATION_ERROR
      }
    },
    [getGooglePlaceToken, getLocationSuggestions]
  )

  return {
    suggestions,
    setRecentSearchLocation,
    fetchGoogleLocationSuggestions,
    resetSuggestions,
    recentSearchLocations,
  }
}
