import { type UserLocation } from '@kijiji/generated/graphql-types'

import { type ApiLocale } from '../locale'

/**
 * Returns a valid label for the location
 * If the location is not a region (province or city), then it returns the street address name
 * If the location is a region, then it returns the region name
 *
 * @param apiLocale
 * @param location
 * @returns
 */
export const getUserLocationLabel = (apiLocale: ApiLocale, location: UserLocation): string => {
  if (location.isRegion) return location.name[apiLocale] || ''

  return location.area?.address || location.name[apiLocale] || ''
}
