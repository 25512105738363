import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type PropsWithChildren, type ReactNode } from 'react'

import { AuthModal } from '@/components/shared/auth-modal/AuthModal'
import { GlobalHeader } from '@/components/shared/global-header'
import { PSABanner } from '@/components/shared/psa-banner/PSABanner'
import { PSA_BANNER_KEYS } from '@/constants/psaBanner'
import { CoreWebVitalScript } from '@/scripts/CoreWebVitalsScript'
import { SpeedCurveScripts } from '@/scripts/SpeedCurveScripts'
import { Flex } from '@/ui/atoms/flex'
import { SkipNavContent, SkipNavLink } from '@/ui/atoms/skip-nav'

import { BaseLayoutBody, BaseLayoutWrapper } from './styled'

const GlobalFooter = dynamic(
  () => import('@/components/shared/global-footer').then((mod) => mod.GlobalFooter),
  { ssr: false }
)

const CURRENT_PSA_CAMPAIGN = PSA_BANNER_KEYS.SITE_MAINTENANCE

export type BaseLayoutProps = PropsWithChildren<{
  /**
   * Defines if the mega menu should be rendered on the search bar
   */
  searchBarChildren?: ReactNode
  /**
   * Will show the search bar in the header. Defaults to false.
   */
  showSearchBar?: boolean
  /**
   * Render an Auth Modal on the page that can be triggered on
   */
  withAuthModal?: boolean
  /**
   *  If true, the main content will fill the full container.
   */
  isMainFullHeight?: boolean
  /**
   * Include the RUM data tracking scripts to speedcurve and corewebvitals.io
   */
  includeRUMScripts?: boolean
  /**
   * RUM data page label to be used for CoreWebVitals.io
   */
  rumPageLabel?: string
  /**
   * Page name used to identify the page (currently used for the header)
   */
  pageName?: string
}>

/**
 * Layout defined for general pages with header + content + footer. Must be used on all root pages.
 */

export const BaseLayout = ({
  children,
  isMainFullHeight = false,
  showSearchBar = false,
  withAuthModal = false,
  includeRUMScripts = false,
  rumPageLabel,
  searchBarChildren,
  pageName,
}: BaseLayoutProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <PSABanner psaKey={CURRENT_PSA_CAMPAIGN} />

      <BaseLayoutWrapper>
        <SkipNavLink contentId="skip-to-main">{t('skip_nav')}</SkipNavLink>

        <BaseLayoutBody>
          <GlobalHeader
            psaCampaign={CURRENT_PSA_CAMPAIGN}
            showSearchBar={showSearchBar}
            pageName={pageName}
          >
            {searchBarChildren}
          </GlobalHeader>

          <SkipNavContent id="skip-to-main" />

          {isMainFullHeight ? (
            <Flex
              flexGrow={1}
              flexBasis="0%"
              flexShrink={1}
              id="base-layout-main-wrapper"
              as="main"
            >
              {children}
            </Flex>
          ) : (
            <main id="base-layout-main-wrapper">{children}</main>
          )}
          {withAuthModal && <AuthModal />}
          <GlobalFooter />
        </BaseLayoutBody>
      </BaseLayoutWrapper>

      {/* START - Temporary speed curve integration scripts to capture RUM (Real User Monitoring data) to be used for CWV audit */}
      {includeRUMScripts && process.env.NODE_ENV === 'production' && (
        <>
          <SpeedCurveScripts />
          <CoreWebVitalScript rumPageLabel={rumPageLabel} />
        </>
      )}
      {/* END */}
    </>
  )
}
