import { type UserLocation } from '@kijiji/generated/graphql-types'
import SearchIcon from '@kijiji/icons/src/icons/Search'
import { isPositiveInteger } from '@kijiji/number/isPositiveInteger'
import {
  type UseComboboxStateChange,
  type UseComboboxStateChangeTypes,
  useCombobox,
} from 'downshift'
import { useTranslation } from 'next-i18next'
import React, {
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  type LocationErrorType,
  type LocationOption,
  DEFAULT_CLOSE_TO_ME_RADIUS,
  LOCATION_ERROR_TYPE,
  LOCATION_OPTION_TYPE,
} from '@/constants/location'
import { LOCATION_MODAL_SEARCH_BAR_DEBOUNCE } from '@/constants/others'
import { getLocationFromRecentSearchLocation } from '@/domain/location/getLocationFromRecentSearchLocation'
import { getRecentSearchLocationFromLocation } from '@/domain/location/getRecentSearchLocationFromLocation'
import { getUserLocationLabel } from '@/domain/location/getUserLocationLabel'
import { useDebounce } from '@/hooks/useDebounce'
import { useFetchLocationFromCoordinates } from '@/hooks/useFetchLocationFromCoordinates'
import { useFetchLocationFromPlaceId } from '@/hooks/useFetchLocationFromPlaceId'
import { useLocale } from '@/hooks/useLocale'
import { useRunOnce } from '@/hooks/useRunOnce'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { FloatingLabel } from '@/ui/atoms/floating-label'
import { Loading } from '@/ui/atoms/loading'
import { TextInput } from '@/ui/atoms/text-input'
import { onEnterPress, onEscapePress } from '@/ui/helpers/keyPress'
import { useIsFocused } from '@/ui/hooks/useIsFocused'
import { InputWrapper } from '@/ui/molecules/search-bar'
import { type CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import {
  LocationList,
  LocationListItem,
  LocationListItemBadge,
  LocationListTitle,
  StyledFormControlLocationInput,
} from './styled'
import { TooltipError } from './TooltipError'
import { useLiveLocation } from './useLiveLocation'
import { useLocationSearchSuggestions } from './useLocationSearchSuggestions'

export type LocationSearchBarProps = {
  isLoadingMap: boolean
  localLocation: UserLocation
  onLocationSubmit: (location: UserLocation, userModifiedRadius?: boolean) => void
  onLocationChange: (location: UserLocation, source?: string) => void
  searchByRegionCheckboxValue: boolean
  setLoading: (isLoading: boolean) => void
  activeError?: LocationErrorType
  setActiveError: (error: LocationErrorType | undefined) => void
  setLocationUsageType: (locationUsageType: {
    isUserUsingCurrentLocation: boolean
    isUserUsingNearbyLocation: boolean
  }) => void
} & Pick<CommonInputFieldProps, 'id' | 'label'>

/**
 * Downshift Autocomplete for SearchLocationModal
 */
export const LocationSearchBar = ({
  id,
  label,
  isLoadingMap,
  localLocation,
  onLocationSubmit,
  onLocationChange,
  searchByRegionCheckboxValue,
  setLoading,
  activeError,
  setActiveError,
  setLocationUsageType,
}: LocationSearchBarProps) => {
  const { t } = useTranslation(['common'])
  const { apiLocale } = useLocale()
  const debounce = useDebounce(LOCATION_MODAL_SEARCH_BAR_DEBOUNCE)
  const {
    suggestions,
    resetSuggestions,
    fetchGoogleLocationSuggestions,
    recentSearchLocations,
    setRecentSearchLocation,
  } = useLocationSearchSuggestions()
  const [searchQuery, setSearchQuery] = useState<string>(
    getUserLocationLabel(apiLocale, localLocation)
  )
  const searchBarRef = useRef<HTMLInputElement>(null)
  const isInputFocused = useIsFocused(searchBarRef)

  const { getLiveLocation } = useLiveLocation()
  const { fetchLocationFromCoordinates } = useFetchLocationFromCoordinates()
  const { fetchLocationFromPlaceId } = useFetchLocationFromPlaceId()

  /**
   * Checks if the option is a live location option and returns "" if it is, otherwise, returns the address
   * @param item item whose text description needs to be shown in input field after selection
   * @returns text to be shown in the input field
   */
  const itemToString = (item: LocationOption | null): string => {
    return item?.type === LOCATION_OPTION_TYPE.LIVE_LOCATION ? '' : item?.address || ''
  }

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    isOpen: isDropdownOpen,
    setHighlightedIndex,
  } = useCombobox<LocationOption | null>({
    items: suggestions,
    itemToString,
    inputValue: searchQuery,
    isOpen: suggestions.length > 0 && isInputFocused,
  })

  useRunOnce(() => {
    !!localLocation.isRegion && searchBarRef.current?.focus()
  })

  useEffect(() => {
    setSearchQuery(getUserLocationLabel(apiLocale, localLocation))
  }, [localLocation, apiLocale])

  const handleOnFocus = () => {
    if (activeError && activeError !== LOCATION_ERROR_TYPE.LOCATION_NOT_SELECTED_ERROR) {
      resetSuggestions()
    }
  }

  const handleOnBlur = (event: FocusEvent) => {
    const { relatedTarget } = event

    //If an option was selected, take no further action. When a dropdown option is selected, the relatedTarget should always be null.
    const wasOptionSelected = !relatedTarget
    if (wasOptionSelected) return

    //If the user typed in a search query that varies from the local location address, remind the user to select a location from the dropdown
    if (
      searchQuery !== localLocation.area?.address &&
      activeError !== LOCATION_ERROR_TYPE.GOOGLE_LOCATION_ERROR
    ) {
      setActiveError(LOCATION_ERROR_TYPE.LOCATION_NOT_SELECTED_ERROR)
    }
  }

  const handleOnInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: newSearchQuery },
    } = event
    setSearchQuery(newSearchQuery)

    /* Do an early return if we should not fetch suggestions */
    const shouldFetchSuggestions = newSearchQuery.length >= 3
    if (!shouldFetchSuggestions) return

    debounce(async () => {
      if (
        newSearchQuery === '' ||
        newSearchQuery === getUserLocationLabel(apiLocale, localLocation)
      ) {
        resetSuggestions()
        return
      }

      const error = await fetchGoogleLocationSuggestions(newSearchQuery, localLocation)

      if (error) {
        setActiveError(error)
        setHighlightedIndex(-1)
        return
      }

      setActiveError(undefined)
      setHighlightedIndex(0)
    })()
  }

  const handleOnKeyPress = (event: KeyboardEvent) => {
    /**
     * Escape key unfocuses the search bar
     * TODO: Does it make more sense to hide the dropdown instead of unfocusing the search bar?
     */
    onEscapePress(event, () => {
      searchBarRef.current?.blur()
    })

    /**
     * Select currently highlighted element on enter press
     */
    onEnterPress(event, () => {
      event.preventDefault()
      if (highlightedIndex < 0) return

      const item = suggestions[highlightedIndex]
      /**
       * Downshift does not change state if the same item is selected. This becomes an
       * issue when enter is pressed on the live location option multiple times. This must
       * be changed when downshift changes the way its inner handler for selecting item
       * changes in a future version. Same with the "onClick" handler of LocationListItem.
       */
      handleOnSelectedItemChange({
        type: '' as UseComboboxStateChangeTypes,
        selectedItem: item,
      })
    })
  }

  const clearError = () => {
    searchBarRef.current?.focus()
    setActiveError(undefined)
  }

  const handleLiveLocationSelection = useCallback(async () => {
    const liveLocation = await getLiveLocation()
    setLoading(false)
    if (!liveLocation) {
      setActiveError(LOCATION_ERROR_TYPE.LIVE_LOCATION_ERROR)
      return
    }
    return liveLocation
  }, [getLiveLocation, setLoading, setActiveError])

  const handleGooglePlaceSelection = useCallback(
    async (placeId: string, address: string) => {
      const fetchedLocation = await fetchLocationFromPlaceId({
        placeId,
        address,
      })
      if (!fetchedLocation) {
        setLoading(false)
        return
      }

      const recentLocation = getRecentSearchLocationFromLocation(
        fetchedLocation,
        searchByRegionCheckboxValue,
        apiLocale
      )

      /**
       * If we have a locationId, then we update our local state and proceed.
       * Otherwise, we fetch another location from the lat/lon pair so that we have
       * a valid locationId.
       */
      const { id, isRegion, area } = fetchedLocation
      if (isPositiveInteger(id)) {
        onLocationChange(fetchedLocation)
        /** Submit the location if a region was selected from the dropdown */
        if (isRegion) {
          if (recentLocation) {
            setRecentSearchLocation(recentLocation)
          }
          onLocationSubmit(fetchedLocation)
        }
        return
      }

      if (area?.latitude && area.longitude) {
        const location = await fetchLocationFromCoordinates({
          latitude: area.latitude,
          longitude: area.longitude,
        })
        if (!location) {
          setLoading(false)
          return
        }
        onLocationChange(location)
      }
    },
    [
      fetchLocationFromPlaceId,
      searchByRegionCheckboxValue,
      apiLocale,
      setLoading,
      onLocationChange,
      onLocationSubmit,
      setRecentSearchLocation,
      fetchLocationFromCoordinates,
    ]
  )

  const handleOnSelectedItemChange = useCallback(
    async (changes: UseComboboxStateChange<LocationOption | null>) => {
      const { selectedItem: selectedOption } = changes
      if (!selectedOption) return

      const { type, suggestionId, address: selectedAddressLabel } = selectedOption
      searchBarRef.current?.blur()
      setLoading(true)

      switch (type) {
        case LOCATION_OPTION_TYPE.LIVE_LOCATION: {
          const liveLocation = await handleLiveLocationSelection()
          if (!liveLocation) return

          setLocationUsageType({
            isUserUsingCurrentLocation: true,
            isUserUsingNearbyLocation: false,
          })
          onLocationChange(liveLocation, 'currentLocationButton')
          return
        }

        case LOCATION_OPTION_TYPE.NEAR_LIVE_LOCATION: {
          const liveLocation = await handleLiveLocationSelection()
          if (!liveLocation) return

          trackEvent({
            action: GA_EVENT.LocationCloseToMeClick,
            label: 'btn_pos=search_bar',
          })
          setLocationUsageType({
            isUserUsingCurrentLocation: false,
            isUserUsingNearbyLocation: true,
          })
          onLocationChange({
            ...liveLocation,
            area: liveLocation.area
              ? { ...liveLocation.area, radius: DEFAULT_CLOSE_TO_ME_RADIUS }
              : null,
          })
          return
        }

        case LOCATION_OPTION_TYPE.RECENT_SEARCH_SUGGESTIONS: {
          const selectedRecentLocation = recentSearchLocations[parseInt(suggestionId)]

          const normalizedSelectedLocation =
            getLocationFromRecentSearchLocation(selectedRecentLocation)

          onLocationChange(normalizedSelectedLocation)

          /** Submit the location if a region was selected from the dropdown */
          if (normalizedSelectedLocation.isRegion) {
            setRecentSearchLocation(selectedRecentLocation)
            onLocationSubmit(normalizedSelectedLocation)
          }
          return
        }
      }

      /* At this point, the selected option has to be a google place suggestion */
      handleGooglePlaceSelection(suggestionId, selectedAddressLabel)
    },
    [
      setLoading,
      handleGooglePlaceSelection,
      handleLiveLocationSelection,
      onLocationChange,
      recentSearchLocations,
      setRecentSearchLocation,
      onLocationSubmit,
      setLocationUsageType,
    ]
  )

  return (
    <>
      <TooltipError clearError={clearError} activeError={activeError}>
        <StyledFormControlLocationInput
          $isFocused={isInputFocused}
          endAdornment={isLoadingMap ? <Loading /> : null}
          startAdornment={<SearchIcon aria-hidden />}
          inputRef={getInputProps().inputRef}
        >
          <InputWrapper>
            <FloatingLabel
              htmlFor={id}
              isFocused={isInputFocused || selectedItem || searchQuery.length > 0}
              {...getLabelProps()}
            >
              {label}
            </FloatingLabel>

            <TextInput
              id={id}
              data-testid="location-search-input"
              {...getInputProps({
                type: 'search',
                onBlur: handleOnBlur,
                onFocus: handleOnFocus,
                onKeyDown: handleOnKeyPress,
                ref: searchBarRef,
                disabled: !!isLoadingMap,
              })}
              /*
                There's an known issue on using controlled values with useCombobox
                so we need to update the state using the built-in onChange instead
                of onInputValueChange in the hook.
                Reference: https://github.com/downshift-js/downshift/issues/1108
              */
              onChange={handleOnInputValueChange}
            />
          </InputWrapper>
        </StyledFormControlLocationInput>
      </TooltipError>
      <LocationList
        isDropdownOpen={isDropdownOpen && suggestions.length}
        data-testid="location-suggestions-list"
        {...getMenuProps()}
      >
        {activeError !== LOCATION_ERROR_TYPE.GOOGLE_LOCATION_ERROR &&
          suggestions.map((option, index) => {
            const { type, address, icon, suggestionId, badge } = option
            const isFirstRecentSearchLocation =
              type === LOCATION_OPTION_TYPE.RECENT_SEARCH_SUGGESTIONS && suggestionId === '0'
            const label = address || ''

            return (
              <React.Fragment key={`search-options-${index}`}>
                {isFirstRecentSearchLocation && (
                  <LocationListTitle key="recent">
                    {t('modals.search_location.labels.recent')}
                  </LocationListTitle>
                )}
                <LocationListItem
                  {...getItemProps({
                    item: option,
                    index,
                    onClick: () =>
                      handleOnSelectedItemChange({
                        type: '' as UseComboboxStateChangeTypes,
                        selectedItem: option,
                      }),
                  })}
                  data-testid="search-list-element"
                  key={`location-list-item-${index}`}
                  aria-label={label}
                  icon={icon}
                  role="button"
                  tabIndex={0}
                  variant="icon"
                  $isHighlighted={index === highlightedIndex}
                >
                  {label}
                  {badge && <LocationListItemBadge>{badge}</LocationListItemBadge>}
                </LocationListItem>
              </React.Fragment>
            )
          })}
      </LocationList>
    </>
  )
}
