export const COOKIE_DELIMITER = '; '
export const MAX_APP_WIDTH = '1440px'
export const CONDENSED_APP_WIDTH = '1095px'
export const SRP_FILTER_HEADER_FOOTER_HEIGHT = '15rem'

export const SEARCH_ROUTE_PREFIX = 'b-'

/** GLOBAL HEADER */
export const MAX_SEARCH_FORM_WIDTH = '60rem'
/**
 * The custom breakpoint doesn't affect the rest of the application
 * It allows the search bar to break properly to the second line when it doesn't fit anymore
 */
export const SEARCH_BAR_SECOND_LINE_BREAK = `@media screen and (min-width:1194px)`

/** Listing constants */
export const MAX_TOP_ADS = 5

export const SHOPPING_ADS = {
  MIN: 5,
  MAX: 15,
}

/** Used for global and location search bar */
export const DEBOUNCE_MS = 200
/** Used for SRP filters selection */
export const FILTERS_DEBOUNCE = 1000
/** Used for location modal radial slider */
export const SLIDER_DEBOUNCE = 50
/** Used for location modal search bar */
export const LOCATION_MODAL_SEARCH_BAR_DEBOUNCE = 500

/** FEEDBACK COMMENT */
export const FEEDBACK_MAX_LENGTH = 200

/** FORM VALIDATION */
export const NAME_MIN_LENGTH = 2

/** MAX INPUT DIGITS */
export const MAXIMUM_INPUT_DIGITS = 7

/** DEALER UPDATES */
export const MAX_ADDITIONAL_OPTIONS_DESKTOP = 4
export const MAX_ADDITIONAL_OPTIONS_TABLET = 3

/** SMALL SCREEN MAX ADS */
export const SMALL_SCREEN_MAX_ADS = 6

/** MINIMUM ADS LENGTH for various viewports */
export const MIN_ADS_COUNT = {
  SMALL_SCREEN: 2,
  MEDIUM_SCREEN: 4,
  LARGE_SCREEN: 5,
}

export const isDevelopment = process.env.NODE_ENV === 'development'

// Get current date
export const CURRENT_DATE = new Date().toISOString().split('T')[0]

export const IMAGE_NOT_AVAILABLE_URL = '/next-assets/images/not-found.jpg'
