import { useDecision } from '@optimizely/react-sdk'
import { type FC } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import { ErrorBoundary } from '@/components/shared/error-boundary'
import { useSession } from '@/hooks/useSession'
import { FEATURE_FLAG } from '@/lib/optimizely'

import { PostAdCTA } from '../PostAdCTA'
import { SearchLocation } from '../search-bar/SearchLocation'
import { LoggedInLinks } from './logged-in-links'
import { LoggedOutLinks } from './logged-out-links'
import { SessionMenu } from './session-menu'
import { ToolsContainer } from './styled'

type ToolsProps = {
  showPostAdCTA: boolean
}

export const Tools: FC<ToolsProps> = ({ showPostAdCTA }) => {
  const { optimisticIsAuthenticated } = useSession()

  const [decision] = useDecision(FEATURE_FLAG.HEADER_SIMPLIFIED)
  const isHeaderSimplified = decision?.enabled === true && decision?.variationKey === 'on'

  return (
    <ClientRender>
      <ToolsContainer data-testid="global-header-tools" isHeaderSimplified={isHeaderSimplified}>
        <ErrorBoundary fingerprintId="Tools">
          {isHeaderSimplified ? (
            <ClientRender>
              <SearchLocation id="set-search-range-header" />
            </ClientRender>
          ) : null}
          {optimisticIsAuthenticated ? (
            <>
              <LoggedInLinks />
              <SessionMenu />
            </>
          ) : (
            <LoggedOutLinks />
          )}
        </ErrorBoundary>

        {showPostAdCTA && <PostAdCTA />}
      </ToolsContainer>
    </ClientRender>
  )
}
