import {
  type Hit,
  type SearchForFacetValuesResponse,
  type SearchResponse,
} from '@algolia/client-search'
import { useGetAlgoliaKeyMutation } from '@kijiji/generated/graphql-types'
import algoliasearch from 'algoliasearch'
import { useState } from 'react'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { sendToLogger } from '@/utils/sendToLogger'

import { getCookieByKey } from '../cookies/getCookieByKey'

// TODO: remove this comment after TypeScript error with Hit Type from algoliaSearch is resolved
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SearchResultHit = Hit<any> & { completion: string }

export const useAlgoliaSearch = () => {
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResultHit[]>([])
  const [algoliaKey] = useGetAlgoliaKeyMutation()

  /**
   * Makes a call to anvil to generate a new algolia token
   */
  const getAlgoliaKey = async () => {
    trackEvent({ action: GA_EVENT.GetAlgoliaKeyAttempt })
    try {
      await algoliaKey({})
    } catch (e) {
      trackEvent({ action: GA_EVENT.GetAlgoliaKeyFail })
      sendToLogger(e, {
        tags: { component: 'search-bar', fn: 'get-algolia-key' },
        fingerprint: ['algoliaSearch', 'getAlgoliaKey'],
      })
    }
  }

  /**
   * Decodes the algoliaToken cookie, which is base64 encoded
   * @param algoliaToken
   * @returns decoded algoliaToken containing app ID, API key, search index, and expiration time
   */
  const decodeAlgoliaToken = (algoliaToken: string) => {
    if (!algoliaToken) {
      return
    }

    let decodedAlgoliaToken
    try {
      decodedAlgoliaToken = JSON.parse(window.atob(algoliaToken))
      return decodedAlgoliaToken
    } catch (e) {
      sendToLogger(e, {
        tags: { component: 'search-bar', fn: 'decode-algolia-token' },
        fingerprint: ['algoliaSearch', 'decodeAlgoliaToken'],
        extra: { algoliaToken },
      })
    }
  }

  /**
   * Checks if algoliaToken is valid. If not, generates a new one
   * @returns decoded algolia token
   */
  const getAlgoliaTokenCookie = async () => {
    let algoliaToken = getCookieByKey(document.cookie, CookieRegistry.ALGOLIA_TOKEN)

    if (algoliaToken == '') {
      await getAlgoliaKey()
      algoliaToken = getCookieByKey(document.cookie, CookieRegistry.ALGOLIA_TOKEN)
    }

    const decodedAlgoliaToken = decodeAlgoliaToken(algoliaToken)
    const currEpochTime = new Date().getTime()

    if (!decodedAlgoliaToken || decodedAlgoliaToken.validUntil < currEpochTime) {
      await getAlgoliaKey()
      algoliaToken = getCookieByKey(document.cookie, CookieRegistry.ALGOLIA_TOKEN)
    }
    return decodeAlgoliaToken(algoliaToken)
  }

  /**
   * Fetches the algolia token, calls search API, sets results in state
   * @param query - The search query typed into SearchField
   */
  const getSearchSuggestions = async (query: string): Promise<SearchResultHit[]> => {
    const algoliaToken = await getAlgoliaTokenCookie()
    if (!algoliaToken) {
      setSearchSuggestions([])
      return []
    }
    const searchClient = algoliasearch(algoliaToken.appId, algoliaToken.apiKey)

    const queries = [
      { indexName: algoliaToken.index[0], query, params: { hitsPerPage: 5 } },
      { indexName: algoliaToken.index[1], query, params: { hitsPerPage: 5 } },
    ]

    if (query.length > 0) {
      trackEvent({ action: GA_EVENT.AlgoliaSearchAttempt })

      try {
        const { results } = await searchClient.search(queries)

        const isSearchResponse = (
          item: SearchResponse | SearchForFacetValuesResponse
        ): item is SearchResponse => Object.keys(item).includes('hits')

        const hits = results.map((item) => {
          return isSearchResponse(item) ? { ...item.hits } : { ...item.facetHits }
        })
        setSearchSuggestions(hits)
        trackEvent({ action: GA_EVENT.AlgoliaSearchSuccess })
        return hits
      } catch (err) {
        trackEvent({ action: GA_EVENT.AlgoliaSearchFail })
        sendToLogger(err, {
          tags: { component: 'search-bar', fn: 'get-search-suggestions' },
          fingerprint: ['algoliaSearch', 'getSearchSuggestions'],
        })
        return []
      }
    } else {
      setSearchSuggestions([])
      return []
    }
  }

  return {
    getSearchSuggestions,
    searchSuggestions,
  }
}
