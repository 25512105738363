import { type LocationArea, type UserLocation } from '@kijiji/generated/graphql-types'
import LocationServiceIcon from '@kijiji/icons/src/icons/LocationServiceOn'
import NearbyWalkIcon from '@kijiji/icons/src/icons/NearbyWalk'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { markerSvgPath } from '@/constants/map'
import { LANGUAGE_KEY } from '@/domain/locale'
import { useFetchLocationFromCoordinates } from '@/hooks/useFetchLocationFromCoordinates'
import GoogleMaps from '@/lib/google/GoogleMaps'
import { Flex } from '@/ui/atoms/flex/Flex'
import { SystemMessage } from '@/ui/molecules/system-message'
import { sendToLogger } from '@/utils/sendToLogger'

import { MapControlButton } from './MapControlButton'
import { MapAnchorDiv, SystemMessageWrapper } from './styled'

const mapCustomButtonsContainerId = 'custom-buttons-container'

export type MapProps = {
  shouldShowMap: boolean
  isRegion?: boolean
  latitude?: LocationArea['latitude']
  longitude?: LocationArea['longitude']
  radius: number
  onLocationChange: (location: UserLocation) => void
  onClickCloseToMe: () => void
  onClickUseCurrentLocation: () => void
  setLocationUsageType: (locationUsageType: {
    isUserUsingCurrentLocation: boolean
    isUserUsingNearbyLocation: boolean
  }) => void
  isUserUsingCurrentLocation?: boolean
  isUserUsingNearbyLocation?: boolean
  isSearchAroundMeEnabled?: boolean
}

export const Map = ({
  shouldShowMap,
  isRegion,
  latitude,
  longitude,
  radius,
  onLocationChange,
  onClickCloseToMe,
  onClickUseCurrentLocation,
  setLocationUsageType,
  isUserUsingCurrentLocation,
  isUserUsingNearbyLocation,
  isSearchAroundMeEnabled,
}: MapProps) => {
  const {
    colors: { purple },
  } = useTheme()

  const mapRef = useRef<google.maps.Map>()
  const markerRef = useRef<google.maps.Marker>()
  const radialCircleRef = useRef<google.maps.Circle>()
  const mapDivRef = useRef<HTMLDivElement>(null)

  const [isInitialized, setIsInitialized] = useState(false)
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [isMapError, setIsMapError] = useState(false)
  const [newLatLong, setNewLatLong] = useState<google.maps.LatLng | null>(null)
  const { t } = useTranslation(['common'])
  const { fetchLocationFromCoordinates } = useFetchLocationFromCoordinates()

  const recenterMap = useCallback((latitude: number, longitude: number) => {
    if (!mapRef.current || !markerRef.current || !radialCircleRef.current) return

    const newCenter = new google.maps.LatLng(latitude, longitude)

    /** Re-center the map */
    if (!newCenter.equals(mapRef.current.getCenter() ?? null)) {
      mapRef.current.panTo(newCenter)
      mapRef.current.setCenter(newCenter)
    }

    /** Re-center the marker */
    if (!newCenter.equals(markerRef.current.getPosition() ?? null)) {
      markerRef.current.setPosition(newCenter)
    }

    /** Re-center the radial circle */
    if (!newCenter.equals(radialCircleRef.current.getCenter() ?? null)) {
      radialCircleRef.current.setCenter(newCenter)
    }
  }, [])

  const updateLocation = useCallback(
    async (latLng: google.maps.LatLng | null) => {
      if (!latLng) return
      const latitude = latLng.lat()
      const longitude = latLng.lng()
      const location = await fetchLocationFromCoordinates({ latitude, longitude })
      if (!location) return
      recenterMap(latitude, longitude)
      /* Re-set the newLatLong value */
      setNewLatLong(null)
      const newLocation = { ...location, radius }
      onLocationChange(newLocation)
    },
    [fetchLocationFromCoordinates, recenterMap, onLocationChange, radius]
  )

  useEffect(() => {
    if (!newLatLong) return
    updateLocation(newLatLong)
  }, [newLatLong, updateLocation])

  useEffect(() => {
    if (!mapRef.current || !markerRef.current) return
    if (isUserUsingCurrentLocation || isUserUsingNearbyLocation) {
      mapRef.current.panTo(markerRef.current.getPosition() as google.maps.LatLng)
    }
  }, [isUserUsingCurrentLocation, isUserUsingNearbyLocation])

  const initGoogleMaps = useCallback(async () => {
    setIsMapError(false)
    try {
      /** Set up google maps with the given language key */
      /*
       * TODO: replace this hardcoded languageKey with the actual one
       * returned by the useLocale once it is fixed
       * Relevant ticket: KCAN-21868
       */
      const googleMapsInstance = GoogleMaps.getInstance(LANGUAGE_KEY.en)
      await googleMapsInstance.setup()

      /** Skip init if libraries did not load, or mapDiv is null */
      if (!googleMapsInstance.areAllLibrariesLoaded || mapDivRef.current === null) {
        return
      }

      /** Create a new map instance if one does not exist */
      if (!mapRef.current) {
        mapRef.current = new google.maps.Map(mapDivRef.current, {
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 14,
        })

        if (isSearchAroundMeEnabled) {
          mapRef.current.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
            document.querySelector(`#${mapCustomButtonsContainerId}`) as HTMLElement
          )
        }

        const finishLoading = () => {
          setIsMapLoading((prev) => {
            if (prev) {
              markerRef.current?.setVisible(true)
              markerRef.current?.setAnimation(google.maps.Animation.DROP)
              radialCircleRef.current?.setVisible(true)
            }

            return false
          })
        }

        const checkIfMarkerIsInBounds = () => {
          if (!mapRef.current || !markerRef.current) return
          const bounds = mapRef.current.getBounds()
          const markerPosition = markerRef.current.getPosition()

          if (bounds && markerPosition) {
            return bounds.contains(markerPosition)
          }

          return false
        }

        mapRef.current.addListener('tilesloaded', finishLoading)
        mapRef.current.addListener('idle', finishLoading)
        mapRef.current.addListener('click', (event: google.maps.MapMouseEvent) => {
          if (!event.latLng) return
          setNewLatLong(event.latLng)
        })
        if (isSearchAroundMeEnabled) {
          mapRef.current.addListener('dragend', () => {
            const isMarkerInBounds = checkIfMarkerIsInBounds()
            if (!isMarkerInBounds) {
              setLocationUsageType({
                isUserUsingCurrentLocation: false,
                isUserUsingNearbyLocation: false,
              })
            }
          })
        }
      }

      /** Create a new marker instance if one does not exist */
      if (!markerRef.current)
        markerRef.current = new google.maps.Marker({
          visible: false,
          animation: google.maps.Animation.DROP,
          icon: {
            path: markerSvgPath,
            fillColor: purple.dark1,
            fillOpacity: 1,
            anchor: new google.maps.Point(14, 34),
          },
        })

      /** Create a new radial circle instance if one does not exist */
      if (!radialCircleRef.current)
        radialCircleRef.current = new google.maps.Circle({
          clickable: false,
          fillColor: purple.light2,
          fillOpacity: 0.1,
          strokeColor: purple.light2,
          strokeWeight: 1,
          visible: false,
        })

      /** Set local state marking map as initialized */
      setIsInitialized(true)
    } catch (e) {
      setIsMapError(true)
      sendToLogger(e, {
        tags: { component: 'map', fn: 'experimentalInitGoogleMaps' },
        fingerprint: ['Map'],
      })
    }
  }, [isSearchAroundMeEnabled, purple.dark1, purple.light2, setLocationUsageType])

  useEffect(() => {
    if (!shouldShowMap || !latitude || isNaN(latitude) || !longitude || isNaN(longitude))
      return undefined
    if (!isInitialized || !mapRef.current || !markerRef.current || !radialCircleRef.current) {
      initGoogleMaps()
      return undefined
    }

    const mapCleanup = () => {
      /**
       * Set map as loading, hide the marker and radial circle and remove the map animation
       */
      setIsMapLoading((prev) => {
        if (!prev) {
          markerRef.current?.setVisible(false)
          markerRef.current?.setAnimation(null)
          radialCircleRef.current?.setVisible(false)
        }
        return true
      })
    }

    /** Re-center the map */
    recenterMap(latitude, longitude)

    /** Remove marker and radial search circle if regional search and return */
    if (isRegion) {
      mapRef.current.setZoom(10)
      markerRef.current.setMap(null)
      radialCircleRef.current.setMap(null)
      return mapCleanup
    }

    /** Show the marker and the radial circle */
    markerRef.current.setMap(mapRef.current)
    radialCircleRef.current.setMap(mapRef.current)

    /** Change the radius if needed */
    if (!(radialCircleRef.current.getRadius() === radius * 1000)) {
      radialCircleRef.current.setRadius(radius * 1000)
    }

    /** Fit the map to the bounds of new radial circle */
    const radiusCircleBounds = radialCircleRef.current.getBounds()
    radiusCircleBounds && mapRef.current.fitBounds(radiusCircleBounds)

    return mapCleanup
  }, [
    initGoogleMaps,
    isInitialized,
    isRegion,
    latitude,
    longitude,
    radius,
    recenterMap,
    shouldShowMap,
  ])

  return (
    <>
      {/*
        The content below represents the custom buttons that are added to the map after being loaded.
        It needs to be wrapped in a div with display: none to prevent it from being rendered on the beginning.
        After the map loads, we append the button to the map.
      */}
      {isSearchAroundMeEnabled && (
        <div style={{ display: 'none' }}>
          <Flex flexDirection="column" id={mapCustomButtonsContainerId} gap="8px">
            <MapControlButton
              tooltipText={t('modals.search_location.buttons.use_current_location')}
              aria-label={
                isUserUsingCurrentLocation
                  ? t('modals.search_location.buttons.use_current_location_active')
                  : t('modals.search_location.buttons.use_current_location')
              }
              id="location-btn"
              onClick={onClickUseCurrentLocation}
              isActive={isUserUsingCurrentLocation}
            >
              <LocationServiceIcon size="default" color="#FFF" />
            </MapControlButton>
            <MapControlButton
              tooltipText={t('modals.search_location.buttons.near_me')}
              id="nearby-btn"
              aria-label={
                isUserUsingNearbyLocation
                  ? t('modals.search_location.buttons.near_me_active')
                  : t('modals.search_location.buttons.near_me')
              }
              onClick={onClickCloseToMe}
              isActive={isUserUsingNearbyLocation}
            >
              <NearbyWalkIcon size="default" color="#FFF" />
            </MapControlButton>
          </Flex>
        </div>
      )}
      {/**
       * MapAnchorDiv is bound to the GoogleMaps instance
       * needs to remain in the DOM when we display the error message
       */}
      <MapAnchorDiv
        data-testid="map-element"
        ref={mapDivRef}
        $isVisible={shouldShowMap && !isMapError}
        $isBlurred={isMapLoading}
      />

      {isMapError && (
        <SystemMessageWrapper>
          <SystemMessage
            variation="error"
            title={t('system_messages.generic_error.title')}
            description={t('modals.search_location.alert_messages.map_load_fail')}
            hideCloseButton
          />
        </SystemMessageWrapper>
      )}
    </>
  )
}
