import KijijiDesktopLogo from '@kijiji/icons/src/icons/KijijiLogo'
import KijijiMobileLogo from '@kijiji/icons/src/icons/KijijiLogoMobile'
import { useTranslation } from 'next-i18next'

import { ROUTES } from '@/constants/routes'

import { RouteLink } from '../route-link'
import { KijijiLogoLink, LogoContainer } from './styled'

export const Logo = () => {
  const { t } = useTranslation('global_header')

  return (
    <RouteLink route={ROUTES.HOMEPAGE}>
      <KijijiLogoLink aria-label={`${t('links.homepage.label')}`}>
        <LogoContainer isDesktop={true}>
          <KijijiDesktopLogo aria-hidden />
        </LogoContainer>

        <LogoContainer isDesktop={false}>
          <KijijiMobileLogo aria-hidden />
        </LogoContainer>
      </KijijiLogoLink>
    </RouteLink>
  )
}
