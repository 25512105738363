import {
  AuthModalVariant,
  GetAuthModalStateDocument,
  useGetAuthModalStateQuery,
} from '@kijiji/generated/graphql-types'
import CloseCircleIcon from '@kijiji/icons/src/icons/CloseCircle'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { useSignin } from '@/hooks/useSignin'
import { Button } from '@/ui/atoms/button'
import signInImage from '~/public/next-assets/images/sign-in-modal-icon.png'

import { LinkCustom } from '../link-custom/LinkCustom'
import {
  AuthModalContent,
  AuthModalFooter,
  AuthModalHeader,
  AuthModalIconContainer,
  AuthModalPanel,
  AuthModalRegisterMessage,
  AuthModalTitle,
  CloseModalButton,
  StyledModal,
} from './styled'

export const AuthModal = () => {
  const { data: { authModalState } = {}, client } = useGetAuthModalStateQuery()

  const { t: tCommon } = useTranslation('common')
  const { t: tSrp } = useTranslation('srp')
  const { t: tRoutes } = useTranslation('routes')

  const isModalOpen = authModalState?.isModalOpen || false
  const callbackUrl = authModalState?.callbackUrl || undefined
  const variant = authModalState?.modalVariant

  let titleText,
    bodyText = ''

  switch (variant) {
    case AuthModalVariant.LoginToFavourite: {
      titleText = tSrp('listing.favourite.login_modal.title')
      bodyText = tSrp('listing.favourite.login_modal.body_text')
      break
    }
    default: {
      // Default no context login prompt should go here
      bodyText = tSrp('listing.favourite.login_modal.body_text')
      titleText = tSrp('listing.favourite.login_modal.title')
    }
  }

  const registerHref = tRoutes('register.href')

  const [goToSignIn, setGoToSignIn] = useState(false)
  useSignin({ callbackUrl: callbackUrl, goToSignIn: goToSignIn })

  const handleAuthClick = () => {
    setGoToSignIn(true)
  }

  const handleCloseAuthModal = () => {
    client.cache.writeQuery({
      query: GetAuthModalStateDocument,
      data: {
        authModalState: {
          isModalOpen: false,
          modalVariant: null,
          callbackUrl: null,
        },
      },
    })
  }

  return (
    <StyledModal
      data-testid="sign-in-favourite-modal"
      id="sign-in-modal"
      isOpen={isModalOpen}
      onCancel={handleCloseAuthModal}
      label={titleText}
    >
      <AuthModalPanel>
        <AuthModalHeader>
          <CloseModalButton
            aria-label={`${tCommon('modals.login.labels.close_login')}`}
            onClick={handleCloseAuthModal}
          >
            <CloseCircleIcon aria-hidden="true" />
          </CloseModalButton>
        </AuthModalHeader>

        <AuthModalIconContainer>
          <Image aria-hidden="true" src={signInImage} alt="" width={210} />
        </AuthModalIconContainer>

        <AuthModalTitle>{titleText}</AuthModalTitle>
        <AuthModalContent>{bodyText}</AuthModalContent>
        <AuthModalFooter>
          <Button
            data-testid="sign-in-modal-button"
            isFullWidth
            onClick={handleAuthClick}
            variant="primary"
          >
            {tCommon('modals.login.labels.sign_in_button')}
          </Button>
          <AuthModalRegisterMessage>
            {tCommon('modals.login.labels.create_account_title')}{' '}
            <LinkCustom href={registerHref} variant="primary">
              {tCommon('modals.login.labels.create_account_link_text')}
            </LinkCustom>
          </AuthModalRegisterMessage>
        </AuthModalFooter>
      </AuthModalPanel>
    </StyledModal>
  )
}
