import {
  type AttributeFilterInput,
  type DateFilterInput,
  type DateRangeFilterInput,
  type RangeFilterInput,
} from '@kijiji/generated/graphql-types'

import { FilterKeysEnum } from '@/hooks/srp/useSearchActions'
import {
  type AppliedFilters,
  isAppliedAttributeFilter,
  isAppliedDateFilter,
  isAppliedDateRangeFilter,
  isAppliedRangeFilter,
  stripTypename,
} from '@/types/search'

/**
 * Categorizes the applied filters based on their sub-types.
 *
 * @param {AppliedFilters} appliedFilters - The array of applied filters.
 * @returns {CategorizedFilters} The categorized filters object.
 */
export const categorizeAppliedFilters = (appliedFilters?: AppliedFilters) => {
  const categorizedFilters = (appliedFilters || []).reduce(
    (acc, filter) => {
      if (isAppliedAttributeFilter(filter)) {
        acc.attributeFilters.push(stripTypename(filter))
      } else if (isAppliedRangeFilter(filter)) {
        acc.rangeFilters.push(stripTypename(filter))
      } else if (isAppliedDateRangeFilter(filter)) {
        acc.dateRangeFilters.push(stripTypename(filter))
      } else if (isAppliedDateFilter(filter)) {
        acc.dateFilters.push(stripTypename(filter))
      }

      return acc
    },
    {
      [FilterKeysEnum.ATTRIBUTE_FILTERS]: [] as AttributeFilterInput[],
      [FilterKeysEnum.RANGE_FILTERS]: [] as RangeFilterInput[],
      [FilterKeysEnum.DATE_RANGE_FILTERS]: [] as DateRangeFilterInput[],
      [FilterKeysEnum.DATE_FILTERS]: [] as DateFilterInput[],
    }
  )

  return categorizedFilters
}
